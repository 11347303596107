import React, { Component } from 'react';
import './NavBar.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Link} from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import {VersionApp} from '../../../services/VersionApp'
import { Avatar } from '@material-ui/core';

class NavBar extends Component {


    
    constructor(props){

        let versionApp = VersionApp();
        
        super(props);
            this.state = {
                id_usuario: props.datosusuario.id_usuario,
                nombre_usuario: props.datosusuario.nombre_usuario,
                id_docente: props.datosusuario.id_docente,
                loading: false,
                anchorEl: null,
                mobileMoreAnchorEl: null,
                openMenu: false,
                versionApp: versionApp
                              
            }
            this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this);
            this.cerrar_session = this.cerrar_session.bind(this);
            this.toggleDrawer = this.toggleDrawer.bind(this);
            this.abrir_perfil = this.abrir_perfil.bind(this);
      }

      handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
     
      handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
      };
      
      cerrar_session = () => {
          this.handleMenuClose();
          this.props.cerrarsession();

      }
      abrir_perfil = () => {
        //console.log("Redireccionando")
        this.handleMenuClose();
       // this.props.abrir_perfil_usuario();
      }      

      handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
      };
      
      handleToggle = () => this.setState({openMenu: !this.state.openMenu});

      toggleDrawer = (side, open) => () => {
        console.log("Abriendo " + open );
        this.setState({
          [side]: open,
        });
      };

    render() {

        const { anchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);


        const renderMenu = (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
          >
            <MenuItem component={Link} to={'/home/perfildeusuario'} onClick={this.abrir_perfil} >
                <i className="material-icons colorAzul">perm_identity</i> Perfil de Usuario
            </MenuItem>
            <MenuItem onClick={this.cerrar_session}>
            <i className="material-icons colorAzul">exit_to_app</i> Salir
            </MenuItem>
          </Menu>
        );
        const drawerWidth = 240;
        const styles = {
          list: {
            width: 450,
          },
          fullList: {
            width: 'auto',
          },
          drawer: {
            width: drawerWidth,
            flexShrink: 0,
          },
          drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            justifyContent: 'flex-end',
          },          
        };


        return (

          <div className="root" >
          <AppBar position="static">
            <Toolbar>
              <IconButton className="menuButton" color="inherit" aria-label="Menu" onClick={this.toggleDrawer('openMenu', true)}>
                <MenuIcon />
              </IconButton>
              <Avatar>
                  <img src="/images/LogoCnotas.png" alt="logo" />
              </Avatar>
              <Typography variant="h6" color="inherit" className="grow">
                    CNotas v.1.{this.state.versionApp}
              </Typography>
              
              <Typography variant="subtitle1" color="inherit">
                  {this.state.nombre_usuario}
              </Typography>
              
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
               <AccountCircle />
              </IconButton>
            </Toolbar>
          </AppBar>
          {renderMenu}
          
          <Drawer
              className={styles.drawer}
              variant="persistent"
              anchor="left"
              open={this.state.openMenu}
              
            >             
            <div className={styles.drawerHeader}>
              
              <IconButton onClick={this.handleToggle}>
                {this.state.openMenu === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <span className="titulomenu_drawer">Menú Principal</span>
            </div>
            <Divider />            
              <List>
                  <ListItem button key={1} component={Link} to={'/home'} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><i className="material-icons colorAzul">home</i></ListItemIcon>
                    <ListItemText primary="Inicio" />
                  </ListItem>
                  <ListItem button key={2} component={Link} to={`/home/actividadesdocente/${this.state.id_docente}`} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><i className="material-icons colorAzul">check_circle_outline</i></ListItemIcon>
                    <ListItemText primary="Actividades" />
                  </ListItem>                  
                  <ListItem button key={2} component={Link} to={`/home/seguimientoap/`} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><DashboardOutlined  className="colorAzul" /></ListItemIcon>
                    <ListItemText primary="Seguimiento Proc. Aprendisaje" />
                  </ListItem>                  
                  
                  <ListItem button key={2} component={Link} to={`/home/revisionseguimientoap/`} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><DashboardOutlined  className="colorAzul" /></ListItemIcon>
                    <ListItemText primary="Revisión de Seguimiento" />
                  </ListItem>                  
                  <ListItem button key={2} component={Link} to={`/home/bajascalificaciones/`} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><DashboardOutlined  className="colorAzul" /></ListItemIcon>
                    <ListItemText primary="Al. Notas Bajas" />
                  </ListItem>                  
                {/*  <ListItem button key={3}>
                    <ListItemIcon><i className="material-icons colorAzul">calendar_today</i></ListItemIcon>
                    <ListItemText primary="Horario" />
                   </ListItem> */}
                  <Divider />
                  <ListItem button key={4} component={Link} to={'/home/asignaturasdocente/'} onClick={() => this.setState({openMenu:false}) }>
                    <ListItemIcon><i className="material-icons colorAzul" >assignment_turned_in</i></ListItemIcon>
                    <ListItemText primary="Notas Perídos Anterior" />
                  </ListItem>
                  {/*
                  <ListItem button key={5}>
                    <ListItemIcon><i className="material-icons colorAzul">insert_chart_outlined</i></ListItemIcon>
                    <ListItemText primary="Informes" />
                  </ListItem>
                  */}
                  <ListItem button key={6} onClick={this.cerrar_session}>
                    <ListItemIcon><i className="material-icons ColorFuxia">exit_to_app</i></ListItemIcon>
                    <ListItemText primary="Salir" />
                  </ListItem>                                                      

              </List>

            </Drawer>          


        </div>

        );
      }

}
export default NavBar;