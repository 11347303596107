import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import {Link} from 'react-router-dom';

import LoadingScreen from 'react-loading-screen'
import {  AppBar, FormControl, IconButton, LinearProgress, Dialog, ListItem, ListItemText, MenuItem, Select, Toolbar, Typography, DialogContent  } from '@material-ui/core';
import { Usuario } from '../../services/Usuario';




class CalificacionesPromedioPeriodo extends Component {
  

  constructor(props){
    super(props);

    let id_grado = null;
    let id_grupo = null;
    let id_gradogrupo = null;
    let id_asignatura = null;

    if (props.match.params.idGrado){
       id_grado = props.match.params.idGrado;
       id_grupo = props.match.params.idGrupo;
       id_asignatura = props.match.params.idAsignatura;
       id_gradogrupo = id_grado + "-" + id_grupo;
    }   

    this.state = {
        usuario_session :  Usuario(),
        id_grado: id_grado,
        id_grupo: id_grupo,
        id_gradogrupo: id_gradogrupo,
        id_asignatura: id_asignatura,
        nom_grado: null,        
        lista_alumnos: null,
        lista_grados: null,
        lista_asignaturas: null,
        titulos_tabla: null,
        titulos_tabla2: null,
        num_periodo: 0,
        nota_alumno: 0,
        id_matricula: 0,
        list_notas: {},
        guardado: {},
        listaindex : {},
        ConfPorcenNotas: [20, 80],
        procentaje_ptl: 0, //% plataforma
        calificacionPTF: false,
        errorvalidarExF: false,
        loading: true,
        loading_bar: false,
        openImprimir: false,
        url_pdf : ""

    }
    this.listar_alumnos = this.listar_alumnos.bind(this);    
    this.periodo_actual = this.periodo_actual.bind(this);
    this.info_grado = this.info_grado.bind(this)
    this.resumen_notas_nomal = this.resumen_notas_nomal.bind(this);
    this.listar_periodos = this.listar_periodos.bind(this);
    this.lista_asignaturas_doc = this.lista_asignaturas_doc.bind(this);
    this.listar_notas_asignatura = this.listar_notas_asignatura.bind(this);
    this.handleCloseImprimir = this.handleCloseImprimir.bind(this);
    this.handleClickOpenImprimir = this.handleClickOpenImprimir.bind(this);
    
    
  }


  lista_grados_doc(){
      console.log("....lista_grados_doc")
      let id_docente = this.state.usuario_session.id_docente;
      GetData('asignaciones/gradosdocente/' + id_docente, this.state).then ((result) =>{
        let responseJSON = result;
        let gradogrupo;
        let lgrados = responseJSON.grados;

        console.log(lgrados)

        

        if (this.state.id_grado !== null)
            gradogrupo = this.state.id_grado + "-" + this.state.id_grupo;
        else{
            gradogrupo = lgrados[0].id_grados + "-" + lgrados[0].id_grupo;  
            
            
            this.setState({
              id_grado: lgrados[0].id_grado,
              id_grupo: lgrados[0].id_grupo,
              id_gradogrupo: gradogrupo,
            })
        }

        console.log("-------->", gradogrupo)
         
        const lista = responseJSON.grados.map((item, index) =><MenuItem key={index} value={`${item.id_grados}-${item.id_grupo}`}>{item.nombre_grado} - {item.id_grupo}</MenuItem>);  

        this.setState({lista_grados: lista, id_gradogrupo: gradogrupo}, () => {

              this.lista_asignaturas_doc();
        })

      });
  }

  cargar_asignaturas = (e) => {
    this.setState({id_gradogrupo: e.target.value}, () =>{
              this.lista_asignaturas_doc();
    });
    
}

  lista_asignaturas_doc(){
      let id_docente = this.state.usuario_session.id_docente;
      let gradogrupo = this.state.id_gradogrupo;
     
      console.log("-->" , gradogrupo);

      let partgradogrupo = gradogrupo.split("-");
      let id_grado = partgradogrupo[0];
      let id_grupo = partgradogrupo[1];

      GetData('asignaciones/asignaturasdocente/' + id_docente + "/" + id_grado + "/" + id_grupo + "/0", this.state).then ((result) =>{
        let responseJSON = result;
        let listasig = responseJSON.asignaturas;
        let gradogrupo = this.state.id_grado + "-" + this.state.id_grupo;        
        let id_asignatura = responseJSON.asignaturas[0].id_asignatura;

        const lista = responseJSON.asignaturas.map((item, index) =><MenuItem key={index} value={item.id_asignatura}>{item.nombre_asignatura}</MenuItem>);  
        this.setState({lista_asignaturas: lista, id_asignatura: id_asignatura}, () => {
              this.listar_alumnos();
        })

      });
  }

  listar_notas_asignatura = (e) => {
    this.setState({id_asignatura: e.target.value}, () =>{
      if (this.state.id_grado !== null)
          this.listar_alumnos();
    });
  }



  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
          this.setState({num_periodo: periodo}, ()=> {
              this.listar_periodos()
            //  this.listar_alumnos();
          });
        }
      });
     
  }

  info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  }  


  
datos_columna(datos, cols){
    let retorno = [];
    
    Object.keys(datos).forEach(function(key) {
      
         

        let datocelda = datos[key];
        let nota = datocelda;
        let center = "";
        
        if (key === "periodo1" || key === "periodo2" || key === "periodo3" || key === "periodo4" || key === "promedio"){
            
        //  console.log(datos['id_matricula'])
            if (!isNaN(parseFloat(datocelda)) && isFinite(datocelda))
                nota = parseFloat(datocelda).toFixed(1);
            center = "center";
        }
        retorno.push(<td align={center} key={`td2${key}`}>{nota}</td>);
    })
    return retorno;
}

anchocolumna(key, total){
    

    let an = total - 3;
    let w = an * 10;
    let anca = 100 - w - 20; 

    if (key === 0)
        return "10%";

    if (key === 1)
        return anca + "%";

    if (key > 1 && key < total)
        return "10%";
    
    if (key === (total-1)){
        return "10%";
    }    
    
}

resumen_notas_nomal(datostabla){
    // se contruye las columnas de con los nombres de los campos para poder armar la tabla

    
    let index = 0;
    //datostabla.forEach(function(alumno){      
    let  alumno = datostabla[0];    
    let columnas = [];
    let columna;

    if (alumno === null || typeof alumno !== 'object') {
      return;
    }
    
    if (Object.keys(alumno).length === 0) {
        return;
    }
  

          Object.keys(alumno).forEach(key => {          
                columna = "";
                switch (key){
                    case "id_matricula":
                          columna = "ID";
                          break;
                    case "nombres_apellidos_al":
                          columna = "Nombre del Alumno";
                          break;
                    case "periodo1":
                          columna = "Periodo 1";
                          break;
                    case "periodo2":
                          columna = "Periodo 2";
                          break;
                    case "periodo3":
                          columna = "Periodo 3";
                          break;
                    case "periodo4":
                          columna = "Periodo 4";
                          break;
                    case "promedio":
                          columna = "Promedio";
                          break;

                }
                columnas.push(columna);
          });            
    //})


    
    let objetos = Object.keys(columnas);
    let totalcol = objetos.length;


    let titulostabla = columnas.map(
        (item, key) =><th key={key} className={"text-center"}  width={this.anchocolumna(key,totalcol)}>{item}</th>);
  
                     
    const renderData = datostabla.map((alumno, key) =>
                <tr key={`tr1${key}`}>{
                    this.datos_columna(alumno, key)
                }</tr>);              



    let listaindi = renderData; //renderData(datostabla, colt_columtotal);                         

    this.setState({ lista_alumnos : listaindi, titulostabla: titulostabla })
    
}


listar_alumnos(){
    this.setState({loading_bar: true})
    let gradogrupo = this.state.id_gradogrupo;
    console.log("-------------> ", gradogrupo)
    let partgradogrupo = gradogrupo.split("-");
    let id_grado = partgradogrupo[0];
    let id_grupo = partgradogrupo[1];

    this.setState({url_pdf: `http://colesper.edu.co/api_res/printer/promedio_notas_asig.php?grado=${id_grado}&grupo=${id_grupo}&asig=${this.state.id_asignatura}&id_periodo=${this.state.num_periodo}&token=${this.state.usuario_session.token}`})

    GetData('calificaciones/listbbnotasperiodo/' + id_grado + "/" + id_grupo + "/" + this.state.id_asignatura + "/" + this.state.num_periodo, this.state).then ((result) =>{
          let responseJSON = result;
          //console.log(responseJSON);
          if (responseJSON.error === false){
             

            this.resumen_notas_nomal(responseJSON.lista);
          
                      
            this.setState({loading: false, loading_bar: false})
             
          }else{
            console.log("Error al cargar las calificaciones");
          }
      })
    }


  cambiar_periodo = (e) => {
      this.setState({num_periodo: e.target.value}, () =>{
        if (this.state.id_grado !== null)
            this.listar_alumnos();
      });
      
  }

  listar_periodos(){
      let periodos_list = [];
  
      for (let i = 1; i <= this.state.num_periodo; i++)
          periodos_list.push(i);
  
      const lista = periodos_list.map((item, index) =><MenuItem key={index} value={item}>Periodo {item}</MenuItem>);
  
      this.setState({lista_periodos: lista})
  
  }      


  handleClickOpenImprimir = () => {
    console.log("Abrir ventana")
    this.setState({openImprimir: true})
  };

  handleCloseImprimir = () => {
    console.log("Cerrar ventana")
    this.setState({openImprimir: false})
  };


  render() {
   
 
  

   
    return (
    
      
    <div className="container" >

      <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                  > 

        <div className="card" >
          <div className="card-header">
                <Button type="button" variant="outlined" component={Link} to={`/home/`} color="primary"> Regresar </Button>                
                <span className="h6 font_plataform fontRoboto LeftMargin">Calificaciones del 
                <strong> Grado:</strong> 
                
                 <FormControl className="ml-3">
                        
                        <Select
                          labelId="label-grado"
                          label="Seleccionar Grado"
                          id="id_periodo"
                          value= {this.state.id_gradogrupo}
                          onChange={this.cargar_asignaturas}
                        >                        
                            { this.state.lista_grados }
                        </Select>
                      </FormControl> 


                <strong> Asignatura:</strong> 
                
                 <FormControl className="ml-3">
                        
                        <Select
                          labelId="label-asignatura"
                          id="id_asignatura"
                          value= {this.state.id_asignatura}
                          onChange={this.listar_notas_asignatura}
                        >                        
                            { this.state.lista_asignaturas }
                        </Select>
                      </FormControl> 

                      <Button type="button" variant="contained" className='ml-2' onClick={this.handleClickOpenImprimir} color="primary"> Imprimir </Button>
                     {/*  <Button type="button" variant="contained" className='ml-2' component={Link} to={`/home/`} color="secondary"> Exportar </Button> */}
             </span>
          </div>
          <div className="card-body">
          <LinearProgress style={{visibility: this.state.loading_bar ? 'visible' : 'hidden' }} />
       

            <h6 className="card-title">Lista de Alumnos</h6>     
            
            
            <table className="table table-bordered table-hover table-sm tablcal tblcalificaciones">
                <thead>
                <tr key={`trh${1}`} className="table-primary">{this.state.titulostabla}</tr>
                <tr key={`trh${2}`}>{this.state.titulos_tabla2}</tr>
                </thead>
                <tbody>{this.state.lista_alumnos}</tbody>
            </table>

            <Button type="button" variant="contained" component={Link} to={`/home/`} color="primary"> Regresar </Button>

          </div>
        </div>

        </LoadingScreen>

        <Dialog fullScreen open={this.state.openImprimir} onClose={this.handleCloseImprimir} >
        <AppBar >
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={this.handleCloseImprimir} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" >
              Imprimir Listado
            </Typography>
            
          </Toolbar>
        </AppBar>
        <DialogContent>
            <iframe src={this.state.url_pdf} className="inframemax" >

            </iframe>
        </DialogContent>
        
      </Dialog>

    </div>
   

    );
  }

  componentDidUpdate(prevProps, prevState) {
       // this[`ref${5}`].handleFocus();
  }
  componentDidMount() {      
    this.periodo_actual();
    //this.info_grado();  
    this.lista_grados_doc();
  }    
}

export default CalificacionesPromedioPeriodo;
