import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import {PostData} from '../../services/PostData';
import LinearProgress from '@material-ui/core/LinearProgress';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { Tooltip } from '@material-ui/core';


class InputNotaFinalP extends Component {
    constructor(props){
        super(props);
        this.state = {
            index: props.index,
            notaalumno: props.nota,
            guardado: false,
            editactivo: false,
            error: false,
            urimg: '/images/save_exito.png'
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.guardar_nota = this.guardar_nota.bind(this);
        this.resetSave = this.resetSave.bind(this);
            
    }

    func_round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }


    func_validarnota(nota){
        const regex = /^[1-4](\.\d)?|5(\.0)?$/;
        if (regex.test(nota))
            return true;
        else
            return false;

        /* switch(nota){
            case 'A':
                return true;
            case 'B':
                return true;
            case 'E':
                return true;
            case 'S':
                return true;
            case 'I':
                return true;
            case 'D':
                return true;
            case 'N':
                return true;
            default:
                return false;

        } */
    }

    handleKeyUp(e) {
        //console.log(e.key);
        
  
        if (e.key === "Enter"){
            console.log("entrando")
            //let notaing = e.target.value;
            const notaing = e.target.value.trim();
            const regex = /^[1-4](\.\d)?|5(\.0)?$/;

            if (regex.test(notaing)) {
                const nota = parseFloat(notaing);
                 console.log(nota)   
                if (nota >= 1.0 && nota <= 5.0) {
                    // La nota es válida, puedes guardarla
                    this.setState({ id_matricula: e.target.id });
                    this.guardar_nota(notaing);
                    console.log("nota guardada")
                } else {
                    console.log("Nota fuera del rango válido (1.0 - 5.0)");
                    this.setState({ guardado: false });
                    return;
                }
            } else {
                console.log("Nota inválida");
                alertify.error('Nota inválida');
                this.setState({ guardado: false });
                return;
            }

           /*  if (notaing === 'A' || notaing ===  'B' || notaing ===  'E' || notaing === 'S' || notaing === 'I' || 'D' || notaing ===  'N'){
                this.setState({id_matricula: e.target.id});                    
                this.guardar_nota(notaing);  
            }else{
                 console.log("InValido")
                 this.setState({guardado: false});
                 return;
            } */
            
            
            
        }else{
             this.setState({guardado: false});
        }
    }

    keyPress(e){
        e.keyCode = 0;
     }    

    guardar_nota(notaing){    
        
        let datosenvio = {
            id_matricula : this.props.id_matricula,
            id_grado: this.props.id_grado,
            id_grupo: this.props.id_grupo,
            id_asignatura: this.props.id_asignatura,            
            nota_alumno: notaing
        } 
       
        if (!notaing){
            this.setState({error: true});
            return;
        }


        if (this.func_validarnota(notaing) === false){
            this.setState({guardado: false, error: true});
            return;
        }        

        this.setState({editactivo: true})

        PostData('notafinalperiodo/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              this.props.tab_control(this.props.id_matricula);       
              this.setState({guardado: true, error: false, editactivo: false});    

           //   return true;
          }else{
              
              this.setState({guardado: false, error: true, editactivo: false});
              console.log("Error al guardar la nota");
             // return false
          }
      })    
  }

  resetSave(e){
    
    this.setState({guardado: false});
      
  }

  convertirnumero(numero){
        if (numero !== ""){
            return parseFloat(numero).toFixed(1)
        }
  }

  deleteNota = () => {
    let datosenvio = {
        id_matricula : this.props.id_matricula,
        id_grado: this.props.id_grado,
        id_grupo: this.props.id_grupo,
        id_asignatura: this.props.id_asignatura,   
    }    
    PostData('notafinalperiodo/deletenota/', datosenvio).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){              
            
                this.props.onChangeNotaDelete(this.props.id_matricula,''); 

                this.setState({guardado: false, error: false, notadelete: false, notaalumno: ''});   
                document.getElementById(String(this.props.id_matricula)).value = "";
                alertify.success('Nota borrada correctamente')
            
         //   return true;
        }else{
            
            this.setState({guardado: false, error: true, notadelete: true});
            console.log("Error al guardar la nota");
           // return false
        }
    }) 
}
  

    render() {

        return (
            <>
            <div className='flex-container'>
            <div>
            <TextField error={this.state.error} 
                    inputProps={{
                        maxLength: 3,
                        style: { textAlign: 'center' }
                    }}
                    innerRef={(el) => { this[`ref${this.props.index}`] = el; }} 
                    name={this.props.id_matricula} 
                    id={this.props.id_matricula} 
                    disabled={this.state.editactivo}
                    autoFocus={false}
                    className="quitarpading stiloimput" 
                    defaultValue={this.state.notaalumno}  
                    style={{ backgroundColor: 'transparent' }}
                    onBlur={this.resetSave}
                    onKeyDown={this.keyPress}
                    onKeyUp={this.handleKeyUp} 
                              onChange={this.props.onChangeNota}
                              onFocus={this.props.handleFocus}
                            />
                    <img src={this.state.urimg} alt="img"  style={{visibility: this.state.guardado ? 'visible' : 'hidden' }} />      
                    <LinearProgress  name={`prog_${this.props.index}`} style={{visibility: this.state.editactivo ? 'visible' : 'hidden' }} />         
                </div>    
                <div>
                    <Tooltip  title="Borrar Nota" aria-label="add">
                    <i className="material-icons ColorAzul puntero" onClick={()=> this.deleteNota() }
                style={{visibility: this.state.notaalumno != "" || this.state.guardado ? 'visible' : 'hidden' }}>
                                        delete_forever
                                        </i>
                    </Tooltip>
                </div> 
                </div>
            </>
        );
    }
    componentDidMount() {
      //  this.setState({open: false});  
  
      }  
}

export default InputNotaFinalP;