import React, { Component } from 'react';
import './Calificaciones.css';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';
import {PostData} from '../../services/PostData';

import {Link} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import LinearProgress from '@material-ui/core/LinearProgress';
import {Usuario} from '../../services/Usuario';
import InputNotaActividad from '../Utilitarios/InputNotaActividad';
import LoadingScreen from 'react-loading-screen'

class Calificaciones extends Component {
  


  constructor(props){
    super(props);
    this.state = {
        usuario_session: Usuario(),
        id_grado: props.match.params.idGrado,
        id_grupo: props.match.params.idGrupo,
        id_asignatura: props.match.params.idAsignatura,
        id_actividades: props.match.params.idActividad,
        id_indicador: props.match.params.idIndicador,
        lista_alumnos: <tr><td></td></tr>,
        num_periodo: 0,
        nota_alumno: 0,
        id_matricula: 0,
        list_notas: {},
        guardado: {},
        listaindex : {},
        min_ganada: 0,
        max_nota: 0,
        nom_grado: "",
        nombre_asing: "",
        errorvalidardoc: false,
        loading: true
    }
    this.listar_alumnos = this.listar_alumnos.bind(this);    
    this.periodo_actual = this.periodo_actual.bind(this);
    this.func_ingresarNota = this.func_ingresarNota.bind(this);
    this.onChangeNota = this.onChangeNota.bind(this);
    this.onChangeNotaDelete = this.onChangeNotaDelete.bind(this);
    this.guardar_nota = this.guardar_nota.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.progreso_guardado = this.progreso_guardado.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.tab_control = this.tab_control.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.info_grado = this.info_grado.bind(this);
    this.info_asigantura = this.info_asigantura.bind(this);
  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  onChangeNotaDelete(name){
    const list_notas = this.state.list_notas;
    list_notas[name] = '';
    this.setState({ list_notas });      
  }  

  onChangeNota(e){
      const list_notas = this.state.list_notas;
      list_notas[e.target.name] = e.target.value;
      this.setState({ list_notas });      
  }  

  func_ingresarNota(id_matricula, nota){
      const list_notas = this.state.list_notas;
      list_notas[id_matricula] = nota
      this.setState({ list_notas });
      return true;      
  } 

  handleFocus(event) {
    event.target.select();
  }

  handleKeyUp(e) {
      //console.log(e.key);
      

      if (e.key === "Enter"){

        
        if(isNaN(e.target.value)){
           console.log("No es un numero")
           return;
         }
         
         let notaing = parseFloat(e.target.value);

         if (notaing > this.state.max_nota ){
            console.log("La nota debe ser menor o igual a " + this.state.max_nota);
            return;
         }
        
          this.setState({id_matricula: e.target.id});
          console.log(e);
         
          this.guardar_nota(e.target.id);         
      }
  }

  updateValue(e) {
      const list_notas = this.state.list_notas;
      list_notas[e.target.name] = e.target.value;
      
      this.setState({ list_notas });
  }  

  progreso_guardado(id_matricula, estado){
            const guardado = this.state.guardado;
            guardado[`prog_${id_matricula}`] = estado;
            this.setState({ guardado });
  }

  tab_control(id_matricula){
      let listaindex = this.state.listaindex;
      let conteo =  Object.keys(listaindex).length;

      Object.keys(listaindex).forEach(function(key) {
            let nums = parseInt(key) + 1;
        
              if (id_matricula === listaindex[key]){
                /** validar que no se pase el key  */
                let indice = listaindex[nums];
              
                if (nums < conteo) {
                    document.getElementById(String(indice)).focus();
                }
                   
                return;
            }
       

                
      });
      return;
  }

  guardar_nota(id_matricula){    
        let notav = this.state.list_notas[id_matricula];
        let datosenvio = {
            id_matricula : id_matricula,
            id_actividades : this.state.id_actividades,
            nota_alumno: notav
        }      
       
        PostData('calificaciones/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              this.tab_control(id_matricula);
           ///   prog_${item.id_matricula}
              this.progreso_guardado(id_matricula, true);
              return true;
          }else{
              console.log("Error al guardar la nota");
              return false
          }
      })    
  }

  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
          this.setState({num_periodo: periodo});
        }
      });
     
  }

  info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let min_ganada = responseJSON.min_ganada;  
        let max_nota = responseJSON.max_nota;  
        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              min_ganada: min_ganada,
              max_nota: max_nota,
              nom_grado: nom_grado
          });
      }
    });    
  }

  info_asigantura(){
    let id_docente = this.state.usuario_session.id_docente;
    GetData('asignaturas/infoasignatura/' + this.state.id_asignatura + "/" + id_docente, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nombre_asing = responseJSON.asignatura;  

        this.setState({
              nombre_asing: nombre_asing
          }, () =>{
                //si no hay errores en la verificación del docente y la asignatura se cargan los alumnos
                this.listar_alumnos();  
          });
      }else{
          this.setState({errorvalidardoc: true})
        
      }
    }); 

    
  }

  focusInput = (id) => this[`ref${id}`].current.focus();

  listar_alumnos(){

    PostData('calificaciones/alumnos/', this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){

                 let guardado = this.state.guardado;
                 let list1_notas = this.state.list_notas;
                 let listaindex = this.state.listaindex;
                Object.keys(responseJSON.alumnos).forEach(function(key) {
                                  
                    let id_matricula = responseJSON.alumnos[key].id_matricula;
                    let notav = responseJSON.alumnos[key].notav;
                    
                    listaindex[key] = id_matricula;

                    if (!notav)
                        notav = "";
                        
                        list1_notas[id_matricula] = notav;
                        
                        

                    //this.func_ingresarNota(id_matricula, notav)



                       guardado[`prog_${id_matricula}`] = false;

                  });
                  
                  this.setState({ list_notas: list1_notas });

              let listaindi = responseJSON.alumnos.map(
                (item, index) =><tr key={item.id_alumnos}>
                        <td>{item.id_matricula}</td>
                        <td>{item.nombres_apellidos_al}</td>
                        <td>
                                                   
                             <InputNotaActividad 
                                 name={item.id_matricula} 
                                 id_matricula = {item.id_matricula}
                                 id_actividades = {this.state.id_actividades}
                                 id={item.id_matricula} 
                                 nota={this.state.list_notas[item.id_matricula]}
                                 onChangeNota={this.onChangeNota}
                                 onChangeNotaDelete={this.onChangeNotaDelete}
                                 handleFocus={this.handleFocus}
                                 tab_control={this.tab_control}
                             />
                              <LinearProgress index={index} name={`prog_${item.id_matricula}`} style={{visibility: this.state.guardado[item.id_matricula] ? 'visible' : 'hidden' }} />
                             
                            </td>
                      </tr>);

                    this.setState({ lista_alumnos : listaindi, list_notas: list1_notas }, () => {
                          this.setState({loading: false});
                    })
             
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
    }

  render() {

    let mensajeerrordocente = "";
    if (this.state.errorvalidardoc === true){
        mensajeerrordocente = <Alert key={1} variant="danger">
            <Alert.Heading>Oh snap! Error de valicación!</Alert.Heading>
            La asignatura no es válida para el Docente actual, de clic en el botón regresar he inténtelo nuevamente 
      </Alert>
    }

    return (
      
    <div className="container" >
        <br />

        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
          logoSrc='/images/LogoCnotasGr.png'
          text='...'
    > 

        <div className="card" >
          <div className="card-header">
                <Button type="button" variant="outlined" component={Link} to={`/home/actividades/${this.state.id_indicador}/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>                
                <span className="h6 font_plataform fontRoboto LeftMargin"><strong>Ingreso de calificaciones</strong>, {this.state.nombre_asing} - <strong>Grado:</strong> {this.state.nom_grado} - 0{this.state.id_grupo}, <strong>Período:</strong>  {this.state.num_periodo} </span>
          </div>
          <div className="card-body">
            <div className="notainfo" >
                  Nota: Las notas se guardan al presionar ENTER, se usa el Punto(.) para los decimales, digite notas con un decimal, ejemplo (3.0) 
            </div>
            <h6 className="card-title">Lista de Alumnos</h6>     
            
            {mensajeerrordocente}

            <table className="table table-bordered table-hover table-sm">
              <thead>
                <tr className="table-primary">
                  <th width="5%">Código</th>
                  <th width="80%">Nombre</th>
                  <th width="8%">Nota</th>
                </tr>
              </thead>
              <tbody>
                {this.state.lista_alumnos}
              </tbody>
            </table>

            <Button type="button" variant="contained" component={Link} to={`/home/actividades/${this.state.id_indicador}/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>

          </div>
        </div>

      </LoadingScreen>

    </div>
   

    );
  }

  componentDidUpdate(prevProps, prevState) {
       // this[`ref${5}`].handleFocus();
  }
  componentDidMount() {
    this.periodo_actual();
    this.info_grado();
    this.info_asigantura();
      
  }    
}

export default Calificaciones;
