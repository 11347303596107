import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import './Indicadores.css';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';

import {Link} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tooltip from '@material-ui/core/Tooltip';
import { Avatar} from '@material-ui/core';
import {Usuario} from '../../services/Usuario';
import LoadingScreen from 'react-loading-screen'
import Moment from 'moment';
import 'moment/locale/es';

class Indicadores extends Component {



  
  constructor(props){
    super(props);

    let usuariosession = Usuario();
    let id_usuario = null;
    let token = null;
    let id_docente = null;

    if (usuariosession){
        id_usuario  = usuariosession.id_usuario;
        token = usuariosession.token;
        id_docente = usuariosession.id_docente;
    }

    this.state = {
        id_usuario: id_usuario,
        id_docente: id_docente,
        token: token,  
        id_asignatura: props.match.params.idAsignatura,
        id_grado: props.match.params.idGrado,
        id_grupo: props.match.params.idGrupo,
        lista_indicadores: <TableRow key={0}><TableCell align="center">Cargando ...</TableCell></TableRow>,
        nombre_asing: null,
        nom_grado: null,
        num_periodo: 0,
        periodo_abierto: false,
        anio_actual: 0,
        nivel_ac: 1,
        fecha_cierre_p: null,
        fecha_exam_f: null,
        btn_exam_f: false,
        btn_acceso_pl: false,
        loading: false,
        lista_periodos: null,
    }
    this.listar_indicadores = this.listar_indicadores.bind(this);
    this.periodo_actual = this.periodo_actual.bind(this);
    this.info_asigantura = this.info_asigantura.bind(this);
    this.func_acceso_plataforma = this.func_acceso_plataforma.bind(this);
    this.info_grado = this.info_grado.bind(this);
   
  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  func_acceso_plataforma(){
    GetData('calificacionesplataforma/habilitadoplaforma/' + this.state.id_grado + "/" + this.state.id_asignatura, this.state).then ((result) =>{
      let responseJSON = result;
      
      if (responseJSON.error === false){
          if (responseJSON.habilitado)
              this.setState({btn_acceso_pl: true})
          else
              this.setState({btn_acceso_pl: false})
      }else{
          this.setState({btn_acceso_pl: false})
      }
      
    });
  }

  info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
        
        let min_ganada = responseJSON.min_ganada;  
        let max_nota = responseJSON.max_nota;  
        let nom_grado = responseJSON.nom_grado;  
        let nivel_ac = responseJSON.nivel;
        this.setState({
              min_ganada: min_ganada,
              max_nota: max_nota,
              nom_grado: nom_grado,
              nivel_ac: nivel_ac
          }, () => {
              this.listar_indicadores();
          });
      }
    });    
  }  

  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{          
        let responseJSON = result;
        
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
          let anio_actual = responseJSON.anio_actual;
          let fecha_cierre_p = responseJSON.fecha_cierre;
          let fecha_exam_f = responseJSON.fecha_examenf;
          let btn_exam_f = false;
          let fechaExamen = Moment(fecha_exam_f).format('YYYY-MM-DD');
          let hoy = Moment().format('YYYY-MM-DD');;
         
          
          if (hoy <= fecha_cierre_p)   
             this.setState({ periodo_abierto: true});          
                    
          if (hoy >= fechaExamen) {  
              btn_exam_f  = true;
          } 
          this.setState({num_periodo: periodo, anio_actual: anio_actual, fecha_cierre_p: fecha_cierre_p, fecha_exam_f: fecha_exam_f, btn_exam_f: btn_exam_f});
        }
      });
  }

  info_asigantura(){
    let id_docente = this.state.id_docente;

    GetData('asignaturas/infoasignatura/' + this.state.id_asignatura + "/" + id_docente, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nombre_asing = responseJSON.asignatura;  

        this.setState({
              nombre_asing: nombre_asing
          });
        
      }else{
          this.setState({errorvalidardoc: true})
          
      }
    }); 
    return true;  
    
  }

  listar_indicadores(){
    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledTableRow = withStyles(theme => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.default,
        },
      },
    }))(TableRow);     

    GetData('indicadores/docenteasingatura/' + this.state.id_grado + '/' + this.state.id_asignatura, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              let listaindi = null;
              
              if (parseInt(this.state.nivel_ac) > 2){
                  listaindi = responseJSON.indicadores.map(
                  item => <StyledTableRow hover  key={item.id_indicador} className="TextoTabla"> 
                              <StyledTableCell  align="left">{item.codigo_indic}</StyledTableCell >
                              <StyledTableCell  align="left">{item.descripcion_indic}</StyledTableCell >
                              <StyledTableCell  align="center">
                                    <Link to={`/home/actividades/${item.id_indicador}/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`}  href="/home/actividades">
                                    <Tooltip title="Ver Actividades" placement="left-start">
                                        <i className="material-icons ColorAzul">
                                        assignment
                                        </i>
                                    </Tooltip>
                                    </Link>   
                              </StyledTableCell >
                          </StyledTableRow >    
                );
              }else{
                listaindi = responseJSON.indicadores.map(
                  item => <StyledTableRow hover  key={item.id_indicador} className="TextoTabla"> 
                              <StyledTableCell  align="left">{item.codigo_indic}</StyledTableCell >
                              <StyledTableCell  align="left">{item.descripcion_indic}</StyledTableCell >
                              <StyledTableCell  align="center">                                    
                                    <Tooltip title="Sin Actividades" placement="left-start">
                                        <i className="material-icons ColorAzul">
                                        assignment
                                        </i>
                                    </Tooltip>
                                    
                              </StyledTableCell >
                          </StyledTableRow >   
                   );         
              }

              
              this.setState({lista_indicadores: listaindi}, () => {
                  this.setState({loading: false})
              });
              
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
      return true;  
    }



  render() {

    if (!this.state.id_usuario){
      return (<Redirect to={'/login'} />)
    }    
    
    const StyledTableCell = withStyles(theme => ({
      head: {
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

  
    let mensajeceroindicadores = "";
    if (this.state.lista_indicadores.length === 0){
        mensajeceroindicadores = <Alert key={1} variant="warning">
             No hay logros establecidos, de clic en el botón <strong>Modificar</strong> para establecer los indicadores del periodo actual. 
      </Alert>
    }
 
    let botonExamenF = null;
    let botonNotaFinal = null;
    let botonAccesoPl = null;
    let nivel_ac = parseInt(this.state.nivel_ac);

/** ****  se comento esta linea por motivos de cuarentena 

    if (nivel_ac >= 2 && this.state.btn_exam_f === true){
          botonExamenF = <Tooltip title="Ingresar Calificaciones del Examen Final" placement="bottom-start">
            <Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/home/examenfinal/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura} color="secondary"> Examen Final </Button></Tooltip>
    }
    
   
    if (this.state.btn_acceso_pl){
      botonAccesoPl = <Tooltip title="Ingresar Calificaciones de la Plataforma" placement="bottom-start"><Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/home/accesoplataforma/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura} color="secondary"> Plataforma </Button></Tooltip>
    }    
*/


  if (nivel_ac === 1){
      botonNotaFinal = <Tooltip title="Ingresar Calificaciones" placement="bottom-start">
      <Button type="button" variant="contained" className="MargenBoton" component={Link} style={{display: this.state.periodo_abierto ? '' : 'none' }}   to={"/home/notafinalperiodo/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura} color="secondary"> Nota Final </Button></Tooltip>
  }

  

    let botonCalificaiones = null;
    if (nivel_ac > 2){
        botonCalificaiones = <Tooltip title="Listado de Promedio Final de la Asignatura" placement="bottom-start"><Button type="button" variant="contained" component={Link} to={"/home/calificacionesfinalp/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura + "/0"} color="secondary"> Calificaciones </Button></Tooltip>
    }
    let mensaje_info = null;
    if (nivel_ac > 2){
        mensaje_info =  <Typography variant="h6" component="h3" className="msginfo"><i className="material-icons colorInfo">info</i>Para seleccionar un identificador de clic en el icono <i className="material-icons ColorAzul">
        assignment
        </i>, Para modificar los indicadores del los periodos de clic en el botón <strong>Modificar</strong>  </Typography>
    }


    return (
      
    <div className="container" >
        <br />

        <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                  > 

        <Grid container spacing={0} >
            <Grid item xs={12}>
              <Paper className="PaddingPaper"  >

                  <Avatar className="IconoPaper">
                                  <i className="material-icons ColorAzul">
                                        assignment_turned_in
                                  </i>
                  </Avatar>  

                  <Typography variant="h5" component="h3" className="Titulo">
                       Indicadores para ({this.state.nom_grado} - {this.state.id_grupo})  -  {this.state.nombre_asing} del Período {this.state.num_periodo}                       
                        del año {this.state.anio_actual} 
                  </Typography>
                  
                    {mensajeceroindicadores}
                    {mensaje_info}

                        <Table className="MargenButton MargenSuperior" >
                              <TableHead className="AltoFila">
                                <TableRow  className="AltoFila"> 
                                  <StyledTableCell className="FilaEncabezadoTabla" width="5%" >Código</StyledTableCell>
                                  <StyledTableCell className="FilaEncabezadoTabla" width="90%" align="left">Concepto</StyledTableCell>
                                  <StyledTableCell className="FilaEncabezadoTabla" width="5%" align="left">Acción</StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                
                                      {this.state.lista_indicadores}
                        
                              </TableBody>
                          </Table>
                     

                  <Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/home/"} color="primary"> Regresar </Button>
                  {botonNotaFinal}
                  {botonExamenF}
                  {botonAccesoPl}
                  {botonCalificaiones}
                  <Tooltip title="Modificar Indicadores" placement="bottom-start">
                  <Button type="button" variant="contained" className="LeftMargin" component={Link} to={"/home/modificarindicadores/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura} color="primary">                     
                      Indicadores                     
                  </Button></Tooltip>  

              </Paper>
            </Grid>
        </Grid>

        </LoadingScreen>
        

    </div>
   

    );
  }
  componentDidMount() {
    this.setState({loading: true});  
    this.info_asigantura();
    this.info_grado();
    this.periodo_actual();
    this.func_acceso_plataforma();
  }    
}

export default Indicadores;
