import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
//import Welcome from './components/Welcome/Welcome';
//import DatosInstitucion from './components/DatosInstitucion/DatosInstitucion';
import NotFound from './components/NotFound/NotFound';
import CambioPassword from './components/CambioPassword/CambioPassword';
import Printer from './components/Printer/Printer';
import PaginaInactivo from './components/PaginaInactivo/PaginaInactivo';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Login} />
            <Route path='/login' component={Login} />
            <Route path='/cambiopassword' component={CambioPassword} />
            <Route path='/home' component={Home} />
            <Route path='/printer' component={Printer} />
            <Route path='/paginainactivo' component={PaginaInactivo} />            
            <Route path='*' component={NotFound} />
            
        </Switch>
    </BrowserRouter>
);

export default Routes;