import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const BarInfoPeriodo = ({bar_info_periodo, periodo, fechacierre, dias_faltantes}) => (
    <div >
            <Grid container spacing={0} >
                <Grid item xs={12}>
                <Paper className="PaddingPaper"  >

                    <Avatar className="IconoPaperHome">
                                    <i className="material-icons">
                                        info
                                    </i>
                    </Avatar>  

                    <Typography variant="h5" component="h3" className="TituloHeader"    >
                       <span style={{visibility: bar_info_periodo ? 'visible' : 'hidden' }}>
                            <strong>Perido actual: </strong> {periodo}  <strong>Fecha de Cierre:</strong> {fechacierre} (<span className="texto_15"> {dias_faltantes}</span>)
                       </span> 
                       <span style={{visibility: bar_info_periodo ? 'hidden' : 'visible' }}>
                            <img src="/images/loading.gif" width="30px"  alt="loading" />  
                       </span>
                    </Typography>
               
                </Paper>
                </Grid>
        </Grid>
    </div>
);

export default BarInfoPeriodo;