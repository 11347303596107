import {URL_SERVICIOS}  from './UrlWebServis';

export function GetData(type){    

    return new Promise((resolve, reject) => {        
        //console.log(URL_SERVICIOS+type)
        fetch(URL_SERVICIOS+type)   
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}