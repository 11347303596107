import { Button, IconButton, Switch, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import LoadingScreen from 'react-loading-screen'
import { GetData } from '../../services/GetData'
import { Usuario } from '../../services/Usuario'

import { Alarm, Check, Edit } from '@material-ui/icons'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import { PostData } from '../../services/PostData'
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const RevisionSeguimiento = () => {

    const usuario_session =  Usuario();
    const IdDocente = usuario_session.id_docente;
  
    const [Loading, setLoading] = useState(false)
    const [EstadoGuardar, setEstadoGuardar] = useState(false)
    const [GradoGrupo, setGradoGrupo] = useState("")
    const [Grado, setGrado] = useState("")
    const [Grupo, setGrupo] = useState("")
    const [ListadoSeguimientos, setListadoSeguimientos] = useState([])

    useEffect(() => {
        let id_docente = usuario_session.id_docente;

        GetData('asignaciones/gradosdirectorgrupo/' + id_docente).then ((result) =>{
          if (result.error === false){       
              setGradoGrupo(result.grado.gradogrupo)        
              setGrado(result.grado.id_grados)
              setGrupo(result.grado.id_grupo)
              
          }
        });

      
    }, [])

    useEffect(() => {
        if (!Grado || !Grupo)
             return;

             GetData('seguimientos/seguimientogrado/' + Grado + '/' + Grupo + '/').then ((result) =>{
            setListadoSeguimientos(result.listado) 
            
            });
    }, [Grado, Grupo, EstadoGuardar])
    
    
   const CambiarRevision = (id_seguim) => {
            alertify.confirm('Confirmar', '¿Confirmas la revisión?', 
            () => {
               
               const datos = {
                     id_seguimientoap: id_seguim
               } 
                // Acción a realizar si se hace clic en el botón "Aceptar"
               PostData('seguimientos/updaterevision', datos).then((result) => {
                    if (result.error === false){
                        setEstadoGuardar(!EstadoGuardar)
                        alertify.notify(result.mensaje, 'success')
                    }
               })
            },
            () => {
                // Acción a realizar si se hace clic en el botón "Cancelar" o se cierra el cuadro de diálogo
                console.log('Acción cancelada');
            });

   }

   const CambiarReporte = (id_seguim) => {
            alertify.confirm('Confirmar', '¿Confirmas que notificaste al padre de familia?', 
            () => {
               
               const datos = {
                     id_seguimientoap: id_seguim
               } 
                // Acción a realizar si se hace clic en el botón "Aceptar"
               PostData('seguimientos/updatereportado', datos).then((result) => {
                    if (result.error === false){
                        setEstadoGuardar(!EstadoGuardar)
                        alertify.notify(result.mensaje, 'success')
                    }
               })
            },
            () => {
                // Acción a realizar si se hace clic en el botón "Cancelar" o se cierra el cuadro de diálogo
                console.log('Acción cancelada');
            });

   }

  return (
    <>
    
    <div className="container" >

        <LoadingScreen
                      loading={Loading}
                      bgColor='#f1f1f1'
                      spinnerColor='#9ee5f8'
                      textColor='#676767'
                      logoSrc='/images/LogoCnotasGr.png'
                      text='...'
                    > 

          <div className="card" >
            <div className="card-header">
                  <Button type="button" variant="outlined" component={Link} to={`/home/`} color="primary"> Regresar </Button>                
                  <span className="h6 font_plataform fontRoboto LeftMargin">
                        <strong>Revisión de Seguimiento de Aprendizaje: Grado:</strong>  {GradoGrupo}


                  </span>
            </div>
         </div>

         <table className="table table-bordered table-hover table-sm tablcal tblcalificaciones">
                  <thead>
                    <tr className='table-primary' key={4545}>
                      <th width='5px'>N°</th>
                      <th width='85px'>Fecha</th>
                      <th width='300px'>Alumno</th>
                      <th width='80px'>Asignatura</th>
                      <th width='5px'>Cat</th>
                      <th>Reporte</th>
                      <th width='5px'>Rev.</th>
                      <th width='5px'>Rep.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListadoSeguimientos.map((seg, key) => {
                      return(
                        <tr key={key}>
                        <td>{key+1}</td>
                        <td>{seg.fecha}</td>
                        <td>{seg.nombres_apellidos_al}</td>
                        <td>{seg.nombre_asignatura}</td>
                        <td>
                            <Tooltip title={seg.nombre_cat_sgap} placement="left-start">
                            <div style={{ backgroundColor: seg.color, width: '15px', height: '15px' }}></div>
                            </Tooltip>
                        </td>                        
                        <td>{seg.reporte_seguimiento}</td>
                        <td width='5px'>
                            {seg.revisado === 'S' ? 
                              <><Check className="colorVerde" /> {seg.fecha_rev}</> : 
                              <Switch
                                        checked={false}
                                        onChange={()=> CambiarRevision(seg.id_seguimientoap)}
                                        name="checkedA"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                              }
                              
                        </td>
                        <td style={{ width: '5px' }}>
                          {seg.reportado === 'S' ? (
                            <><Check className="colorVerde" /> {seg.fecha_rep}</>
                          ) : (
                                <Switch
                                        checked={false}
                                        name="checkedB"
                                        onChange={()=> CambiarReporte(seg.id_seguimientoap)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                          )}
                        </td>
                        
                        </tr>
                      )
                    })}
                      
                  </tbody>
              </table>
              { ListadoSeguimientos.length === 0 &&
                 <Alert key={1} variant="warning" style={{padding: '2px'}}>
                  Sólo los Coordinadores de grado puedes hacer las revisión de los seguimientos al proceso de Aprendizaje 
                </Alert>
              }

      </LoadingScreen>
      </div>          
    </>
  )
}

export default RevisionSeguimiento