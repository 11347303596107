import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';
import CloseIcon from '@material-ui/icons/Close';
import LoadingScreen from 'react-loading-screen'
import {Link} from 'react-router-dom';
import { Usuario } from '../../services/Usuario';
import { useEffect } from 'react';
import { PostData } from '../../services/PostData';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from 'react-bootstrap';
import { Check, Alarm, Edit } from '@material-ui/icons';

const SeguimientoAprendizaje = () => {

  
  let id_grado = null;
  let id_grupo = null;
  let id_gradogrupo = null;
  let id_asignatura = null;
  const usuario_session =  Usuario();
  const IdDocente = usuario_session.id_docente;

  const [Loading, setLoading] = useState(false)
  const [LoadingAlumnos, setLoadingAlumnos] = useState(false)
  const [EstadoModal, setEstadoModal] = useState(false)
  const [Asignatura, setAsignatura] = useState(null)
  const [Periodo, setPeriodo] = useState(null)
  const [IdSegimiento, setIdSeguimiento] = useState(0)
  const [Alumno, setAlumno] = useState("")
  const [NombreAlumno, setNombreAlumno] = useState("")
  const [NotaSeguimiento, setNotaSeguimiento] = useState("")
  const [CategoriaSegui, setCategoriaSegui] = useState("")
  const [DescripcionSegui, setDescripcionSegui] = useState("")
  
  const [GradoGrupo, setGradoGrupo] = useState(null)
  const [ListaGrados, setListaGrados] = useState([])
  const [ListaAsignaturas, setListaAsignaturas] = useState([])
  const [ListadoAlumnos, setListadoAlumnos] = useState([])
  const [ListadoSeguimientos, setListadoSeguimientos] = useState([])
  const [CategoriasSeguimientos, setCategoriasSeguimientos] = useState([])


  useEffect(() => {
      setLoading(true)
      periodo_actual();      
      Seguimientos()
      ListaGradosDoc()     
      
  }, [])

  useEffect(() => {
        ListaAsignaturasDoc();
        ListarAlumnos()
  }, [GradoGrupo])

  useEffect(() => {
      ListaSeguimiento();

  }, [GradoGrupo, Asignatura])

  useEffect(() => {
        DescripcionSeguimiento();
  }, [CategoriaSegui])
  

  const AbrirModal = () => {
   /*  const alumnoEncontrado = ListadoAlumnos.find(alumno => parseInt(alumno.id_matricula) === parseInt(Alumno));    
    
    if (alumnoEncontrado) {
      const nombresApellidos = alumnoEncontrado.nombres_apellidos_al;
      setNombreAlumno(nombresApellidos)
    } */
      setEstadoModal(!EstadoModal)
  }

  const DescripcionSeguimiento = () => {
        setDescripcionSegui("")
        const seguimiento = CategoriasSeguimientos.find(seg => parseInt(seg.id_categoria_sgap) === parseInt(CategoriaSegui));  
        if (seguimiento)
            setDescripcionSegui(seguimiento.descipcion_cat_sgap)
  }

  const ListarAlumnos = () => {

        if (!GradoGrupo)
            return;

        const partgradogrupo = GradoGrupo.split("-");
        const id_grado = partgradogrupo[0];
        const id_grupo = partgradogrupo[1];

        const datos = {
          id_grado: id_grado,
          id_grupo: id_grupo
        }
        setLoadingAlumnos(true)
        PostData('matriculas/listaalumnosgrado/', datos).then ((result) => {
              setListadoAlumnos(result.alumnos)
              setLoadingAlumnos(false)
        });
  }

  const ListaGradosDoc = () => {
    
      let id_docente = usuario_session.id_docente;

      GetData('asignaciones/gradosdocente/' + id_docente).then ((result) =>{
        setListaGrados(result.grados) 
        if (result.grados[0].gradogrupo){       
            setGradoGrupo(result.grados[0].gradogrupo)        
           
        }
      });
  }

  const ListaSeguimiento = () => {
      if (!GradoGrupo)
          return;
      const partgradogrupo = GradoGrupo.split("-");
      const id_grado = partgradogrupo[0];
      const id_grupo = partgradogrupo[1];

      GetData('seguimientos/seguimientogradoasig/' + id_grado + '/' + id_grupo + '/' + Asignatura).then ((result) =>{
        setListadoSeguimientos(result.listado) 
       
      });
  }

  const Seguimientos = () => {
      GetData('seguimientos/').then ((result) =>{
        setCategoriasSeguimientos(result.listado)        
      });
  }

    const ListaAsignaturasDoc = () => {

          if (!GradoGrupo)
          return;

          const partgradogrupo = GradoGrupo.split("-");
          const id_grado = partgradogrupo[0];
          const id_grupo = partgradogrupo[1];

          GetData('asignaciones/asignaturasdocente/' + IdDocente + "/" + id_grado + "/" + id_grupo + "/0").then ((result) =>{
           
            setListaAsignaturas(result.asignaturas)
            if (result.asignaturas[0])
                setAsignatura(result.asignaturas[0].id_asignatura)
            setLoading(false)
          });
    }

   const periodo_actual = () => {
      GetData('periodos/actual/').then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            const periodo = responseJSON.periodo;  
            setPeriodo(periodo)
            ListaGradosDoc(); 
        }
      });
     
  }

  const LimpiarModal = () => {
        setIdSeguimiento(0)
        setCategoriaSegui('')
        setNotaSeguimiento('')
  }

  const NuevoSeguimiento = () => {
        LimpiarModal();
        AbrirModal();
  }

  const ModificarSeguimiento = (datos) => {
        setIdSeguimiento(datos.id_seguimientoap)
        setAlumno(datos.id_matricula)
        setNombreAlumno(datos.nombres_apellidos_al)
        setCategoriaSegui(datos.id_categoria_sgap)
        setNotaSeguimiento(datos.reporte_seguimiento)
        AbrirModal()
  }
  const GuardarSegui = () => {
        const datos = {
          id_seguimientoap: IdSegimiento,
          id_matricula: Alumno,
          id_asignatura: Asignatura,
          id_docente: IdDocente,
          reporte_seguimiento: NotaSeguimiento,
          id_categoria_sgap: CategoriaSegui
        }

        PostData('seguimientos/guardar', datos).then((result) => {
              if (result.error === false){
                  setEstadoModal(!EstadoModal)
                  ListaSeguimiento();
                  LimpiarModal()
              }
        })
  }


  return (
    <>
    
      <div className="container" >

        <LoadingScreen
                      loading={Loading}
                      bgColor='#f1f1f1'
                      spinnerColor='#9ee5f8'
                      textColor='#676767'
                      logoSrc='/images/LogoCnotasGr.png'
                      text='...'
                    > 

          <div className="card" >
            <div className="card-header">
                  <Button type="button" variant="outlined" component={Link} to={`/home/`} color="primary"> Regresar </Button>                
                  <span className="h6 font_plataform fontRoboto LeftMargin">
                  <strong> Grado:</strong> 
                  
                  <FormControl className="ml-3">
                          
                          <Select
                            labelId="label-grado"
                            label="Seleccionar Grado"
                            id="id_periodo"
                            value= {GradoGrupo}
                            onChange={(e) => setGradoGrupo(e.target.value)}
                          >   
                          {ListaGrados.map((grado, index) => {
                            return (
                              <MenuItem key={index} value={`${grado.id_grados}-${grado.id_grupo}`}>{grado.nombre_grado} - {grado.id_grupo}</MenuItem>
                            );
                          })}                   
                              
                          </Select>
                        </FormControl> 


                  
                  
                          <strong> Asignatura:</strong> 
                  <FormControl className="ml-3">
                          <Select
                            labelId="id_asignatura"
                            id="id_asignatura"
                            value= {Asignatura}
                            onChange={(e) => setAsignatura(e.target.value)}
                          >                                                      
                              {ListaAsignaturas.map((asig, index) => {
                            return (
                              <MenuItem key={index} value={asig.id_asignatura}>{asig.nombre_asignatura}</MenuItem>
                            );
                          })}  
                          </Select>
                        </FormControl> 
                  
                        <strong> Alumno:</strong> 
                        <FormControl className="ml-3" style={{minWidth:'200px'}}>
                          <Select
                            labelId="id_alumno"
                            id="id_alumno"
                            value= {Alumno}
                            onChange={(e) => {

                              setAlumno(e.target.value);
                              console.log(e.target.value)
                              const alumnoEncontrado = ListadoAlumnos.find(alumno => parseInt(alumno.id_matricula) === parseInt(e.target.value));    
    
                              if (alumnoEncontrado) {
                                const nombresApellidos = alumnoEncontrado.nombres_apellidos_al;
                                setNombreAlumno(nombresApellidos)
                              }                              
                            }}
                          >                        
                          <MenuItem index={0} value="">
                            <em>Seleccione el Alumno</em>
                          </MenuItem>
                              {ListadoAlumnos.map((mat, index) => {
                            return (
                              <MenuItem key={index+1} value={mat.id_matricula}>{mat.nombres_apellidos_al}</MenuItem>
                            );
                          })}  
                          </Select>
                          <FormHelperText>Debe seleccionar un alumno</FormHelperText>
                        </FormControl> 
                        <FormControl className="ml-3">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<AddIcon />}
                          onClick={()=> NuevoSeguimiento()}
                          disabled={Alumno=== ""}
                        >
                          Nuevo
                        </Button>
                        </FormControl>
                      {/*  <Button type="button" variant="contained" className='ml-2' component={Link} to={`/home/`} color="secondary"> Exportar </Button> */}
              </span>
            </div>
            <div className="card-body">
            <LinearProgress style={{visibility: LoadingAlumnos ? 'visible' : 'hidden' }} />
        

              <h6 className="card-title">Seguimiento de Aprendizaje - Lista de Alumnos</h6>     
              
              <Alert key={1} variant="info" style={{padding: '2px'}}>
                    <strong>Rev:</strong> Revisado por el Coordinador de grado <strong>Rep:</strong> Reportado al padre de familia
              </Alert>

              <table className="table table-bordered table-hover table-sm tablcal tblcalificaciones">
                  <thead>
                    <tr className='table-primary' key={4545}>
                      <th width='5px'>Id</th>
                      <th width='320px'>Alumno</th>
                      <th width='5px'>Cat</th>
                      <th>Reporte</th>
                      <th width='5px'>Rev.</th>
                      <th width='5px'>Rep.</th>
                      <th width='5px'>Op</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListadoSeguimientos.map((seg, key) => {
                      return(
                        <tr key={key}>
                        <td>{key+1}</td>
                        <td>{seg.nombres_apellidos_al}</td>
                        <td>
                            <Tooltip title={seg.nombre_cat_sgap} placement="left-start">
                            <div style={{backgroundColor:seg.color, width: '15px', height: '15px'}}></div>
                            </Tooltip>
                        </td>
                        <td>{seg.reporte_seguimiento}</td>
                        <td width='5px'>
                            {seg.revisado === 'S' ? 
                              <Check className="colorVerde" /> : 
                              <Tooltip title="Esperando Revisión por el Coordinador de grado" placement="left-start">
                                  <Alarm className="ColorFuxia" />
                              </Tooltip>
                              }
                        </td>
                        <td style={{ width: '5px' }}>
                          {seg.reportado === 'S' ? (
                            <Check className="colorVerde" />
                          ) : (
                            <Tooltip title="Esperando que se Reporte" placement="left-start">
                              <Alarm className="ColorFuxia" />
                            </Tooltip>
                          )}
                        </td>
                        <td>
                          {seg.revisado === 'N' && (
                            <Tooltip title="Modificar Seguimiento" placement="left-start">
                              <IconButton className="colorVerde" onClick={() => ModificarSeguimiento(seg)}>
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                        </td>
                        </tr>
                      )
                    })}
                   <span class="material-symbols-outlined">

</span>
                      
                  </tbody>
              </table>

              <Button type="button" variant="contained" component={Link} to={`/home/`} color="primary"> Regresar </Button>

            </div>
          </div>

          </LoadingScreen>

          <Dialog maxWidth='lg' open={EstadoModal} onClose={AbrirModal}  aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">{IdSegimiento === 0 ? <>Agregar Seguimiento</> : <>Modificar Seguimiento</>} </DialogTitle>
          <DialogContent>
              <Typography gutterBottom>
                Alumno: {NombreAlumno}
              </Typography>
              <strong> Categoria Seguimiento:</strong> 
              <FormControl className="ml-3" style={{width:'400px'}}>
                          
                          <Select
                            labelId="label-grado"
                            label="Seleccionar Categoria"
                            id="id_periodo"
                            value= {CategoriaSegui}
                            onChange={(e) => setCategoriaSegui(e.target.value)}
                          >   
                          {CategoriasSeguimientos.map((seg, index) => {
                            return (
                              <MenuItem key={index} value={`${seg.id_categoria_sgap}`}>{seg.nombre_cat_sgap}</MenuItem>
                            );
                          })}                   
                              
                          </Select>
                        </FormControl> 
                          <span className="alert alert-warning d-flex align-items-center" style={{padding:'2px', marginTop:'3px', fontSize: '11px'}}>
                            {DescripcionSegui}</span>
                        <FormControl className="ml-3 mt-3" style={{width:'100%', minHeight:'300px'}}>
                            <TextField
                            id="filled-multiline-flexible"
                            label="Digite el Seguimiento"
                            multiline
                            rows={10}
                            
                            value={NotaSeguimiento}
                            onChange={(e) => setNotaSeguimiento(e.target.value)}
                            variant="filled"
                            fullWidth
                          />
                       
                       </FormControl>
          </DialogContent>
              <DialogActions>
                <Button onClick={GuardarSegui} color="primary" disabled={!CategoriaSegui || !NotaSeguimiento}>
                  Guardar
                </Button>
                <Button onClick={AbrirModal} color="secundary">
                  Cerrar
                </Button>
              </DialogActions>
            
        </Dialog>

      </div>
    
    </>
  )
}

export default SeguimientoAprendizaje