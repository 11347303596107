import React, { Component, lazy, Suspense } from 'react';
import {Route} from 'react-router-dom';
import './Home.css';
import {GetData} from '../../services/GetData'
import {Usuario} from '../../services/Usuario'
import {Redirect} from 'react-router-dom';
import GradoMaterias from './GradoMaterias/GradoMaterias';
import NavBar from './NavBar/NavBar';
import Grados from './Grados/Grados';
//import Indicadores from '../Indicadores/Indicadores';
import Actividades from '../Actividades/Actividades';
import Calificaciones from '../Calificaciones/Calificaciones';
import CalificacionesIndicador from '../CalificacionesIndicador/CalificacionesIndicador';
import ModificarIndicadores from '../ModificarIndicadores/ModificarIndicadores'
import CalificacionesPeriodo from '../CalificacionesPeriodo/CalificacionesPeriodo';
import NotaFinalPeriodo from '../NotaFinalPeriodo/NotaFinalPeriodo';
import Consolidado from '../Consolidado/Consolidado';
import Calificacionesfinalp from '../Calificacionesfinalp/Calificacionesfinalp';
import Indicadores from '../Indicadores/Indicadores';
import ExamenFinal from '../ExamenFinal/ExamenFinal';
import IdleTimer from 'react-idle-timer'
import BarInfoPeriodo from './BarInfoPeriodo';
import Moment from 'moment';
import 'moment/locale/es';
import ConsolidadoResumen from '../Consolidado/ConsolidadoResumen';
import CalificacionesPromedioPeriodo from '../Calificaciones/CalificacionesPromedioPeriodo';
import SeguimientoAprendizaje from '../Seguimiento/SeguimientoAprendizaje';
import RevisionSeguimiento from '../Seguimiento/RevisionSeguimiento';
import AlumnosNotasPerdidas from '../Calificaciones/AlumnosNotasPerdidas';







const CalificacionesPeriodoAnterior = lazy(() => import("../CalificacionesPeriodoAnterior/CalificacionesPeriodoAnterior"))
const AccesoPlataforma = lazy(() => import("../AccesoPlataforma/AccesoPlataforma"));
const ListadoAlumnos = lazy(() => import("../ListadoAlumnos/ListadoAlumnos"));
const ListadoAlumnosDatos = lazy(() => import("../ListadoAlumnos/ListadoAlumnosDatos"));
const ObservadorAlumno = lazy(() => import("../ObservadorAlumno/ObservadorAlumno"));

const InformeAlumno = lazy(() => import("../InformeAlumno/InformeAlumno"));
const PerfilUsaurio = lazy(() => import("../PerfilUsuario/PerfilUsuario"));
const InformeAlumnobc = lazy(() => import("../InformeAlumnobc/InformeAlumnobc"));
const ActividadesDocente = lazy(() => import("../ActividadesDocente/ActividadesDocente"));
const AsignaturasDocente = lazy(() => import("../AsignaturasDocente/AsignaturasDocente"));
const NotaFinalPeriodoAnterior = lazy(() => import("../NotaFinalPeriodoAnterior/NotaFinalPeriodoAnterior"));


class Home extends Component {



  constructor(props){
      
      super(props);
      this.state = {
          usuario_session: Usuario(),
          id_docente: 0,
          username:'',
          password:'',
          lista_grados: <div></div>,
          redirect: false,
          usuarios_inactivo: false,
          error_login: false,
          periodo_actual : null,
          anio_actual :  null,
          fecha_cierre_p :  null,
          fecha_examenf_p :  null,
          dias_faltantes_cierre: "",
          bar_info_periodo: false
      }
      this.listar_grados = this.listar_grados.bind(this);
      this.cerrar_session = this.cerrar_session.bind(this);    
      this.idleTimer = null
      this.onAction = this._onAction.bind(this)
      this.onActive = this._onActive.bind(this)
      this.onIdle = this._onIdle.bind(this);
      this.obtener_periodo_actual = this.obtener_periodo_actual.bind(this)
      this.dias_faltantes = this.dias_faltantes.bind(this);
  }

  cerrar_session = () => {
     sessionStorage.clear();
     this.setState({usuario_session: Usuario()});
  }


  _onAction(e) {
  //  console.log('el usuario hizo algo', e)
  }
 
  _onActive(e) {
   // console.log('user esta active', e)
   // console.log('time restante', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {
    //console.log('el usuario está inactivo', e)
   // console.log('activo por última vez', this.idleTimer.getLastActiveTime());
    sessionStorage.clear();
    this.setState({usuarios_inactivo: true});
  }

  obtener_periodo_actual(){
        GetData('periodos/actual/', this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              
            this.setState({
              periodo_actual :  responseJSON.periodo,
              anio_actual : responseJSON.anio_actual,
              fecha_cierre_p : responseJSON.fecha_cierre,
              fecha_examenf_p : responseJSON.fecha_examenf
            }, () => {
               this.dias_faltantes();
            })
          }
        });
  }

  dias_faltantes(){
    let hoy = Moment().format('YYYY-MM-DD');
    
    if (this.state.fecha_cierre_p >= hoy)  {
        this.setState({
          dias_faltantes_cierre: Moment(this.state.fecha_cierre_p, "YYYYMMDD").endOf('day').fromNow(),
          bar_info_periodo: true
        })
        return;
    }else{   
      this.setState({
        dias_faltantes_cierre: "Período Cerrado",
        bar_info_periodo: true
      }) 
      return;     
    }    
  }

  formato_fecha(fecha){
    if (fecha !== "0000-00-00")
       return Moment(fecha).format('DD/MMM/YYYY');
    else
       return "Sin Fecha";    
  }


  listar_grados(){
  
    if (!this.state.usuario_session)
       return; 

    let id_docente = this.state.usuario_session.id_docente;
    GetData('asignaciones/gradosdocente/' + id_docente, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              let lasigna = responseJSON.grados.map(
                    item => <div key={item.id_grados} className="card anchocard ">
                              <div className="card-header bg-info text-white">
                                 {item.nombre_grado} {item.num_grupo}
                              </div>
                              <div className="container">
                                  <GradoMaterias id_grados={item.id_grados} id_docente={this.state.usuario_session.id_docente}  />
                              </div>
                  </div>);
              
              this.setState({lista_grados: lasigna});
              
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
  }  

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
    

    if (!this.state.usuario_session) {
        return (<Redirect to={'/login'} />)
    }

    if (this.state.usuarios_inactivo){
       return (<Redirect to={'/paginainactivo'} />)
    }

    return (
      
          

    <div >
          <NavBar datosusuario={this.state.usuario_session} cerrarsession={this.cerrar_session.bind(this)} />
          <BarInfoPeriodo bar_info_periodo={this.state.bar_info_periodo} id_docente= {this.state.usuario_session.id_docente} dias_faltantes={this.state.dias_faltantes_cierre} periodo = {this.state.periodo_actual} fechacierre = {this.formato_fecha(this.state.fecha_cierre_p)} />
          <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />

          <Suspense fallback={<><div className="logoapp"></div><div className="loading"></div></>}>
                <Route path='/home' exact component={props => <Grados id_periodo={this.state.periodo_actual} />} />
                <Route path='/home/indicadores/:idGrado/:idGrupo/:idAsignatura' component={Indicadores}  exact />
                <Route path='/home/actividades/:IdIndicador/:idGrado/:idGrupo/:idAsignatura' exact component={Actividades} />
                <Route path='/home/calificaciones/:idIndicador/:idActividad/:idGrado/:idGrupo/:idAsignatura' exact component={Calificaciones} />
                <Route path='/home/calificacionesindicador/:idIndicador/:idGrado/:idGrupo/:idAsignatura/:Periodo' exact component={CalificacionesIndicador} />
                <Route path='/home/examenfinal/:idGrado/:idGrupo/:idAsignatura' component={ExamenFinal} exact />
                <Route path='/home/calificacionesperiodos/:idGrado/:idGrupo/:idAsignatura/:idPeriodo' component={CalificacionesPromedioPeriodo} exact />
                <Route path='/home/calificacionesperiodos/' component={CalificacionesPromedioPeriodo} exact />                
                <Route path='/home/seguimientoap/' component={SeguimientoAprendizaje} exact />                
                <Route path='/home/revisionseguimientoap/' component={RevisionSeguimiento} exact />                
                <Route path='/home/bajascalificaciones/' component={AlumnosNotasPerdidas} exact />                
                <Route path='/home/calificacionesperiodo/:idGrado/:idGrupo/:idAsignatura' component={CalificacionesPeriodo} exact />
                <Route path='/home/calificacionesperiodoanterior/:idGrado/:idGrupo/:idAsignatura/:Periodo' component={props => <CalificacionesPeriodoAnterior {...props} />} exact />
                <Route path='/home/accesoplataforma/:idGrado/:idGrupo/:idAsignatura' component={props => <AccesoPlataforma {...props} />} exact />
                <Route path='/home/listadoalumnos/:idGrado/:idGrupo/:idDocente/:idPeriodo' component={props => <ListadoAlumnos {...props} />} exact />
                <Route path='/home/listadoalumnosdatos/:idGrado/:idGrupo/:idDocente/:idPeriodo' component={props => <ListadoAlumnosDatos {...props} />} exact />
                <Route path='/home/observadoralumno/:idGrado/:idGrupo/:idDocente/:idMatricula' component={props => <ObservadorAlumno {...props} />} exact />
                <Route path='/home/notafinalperiodo/:idGrado/:idGrupo/:idAsignatura' component={props => <NotaFinalPeriodo {...props} />} exact />
                <Route path='/home/notafinalperiodoanterior/:idGrado/:idGrupo/:idAsignatura/:idPeriodo' component={props => <NotaFinalPeriodoAnterior {...props} />} exact />
                <Route path='/home/informealumno/:idMatricula/:idGrado/:idGrupo/:idDocente/:idPeriodo' component={props => <InformeAlumno {...props} />} exact />
                <Route path='/home/perfildeusuario' component={props => <PerfilUsaurio {...props} />} exact />
                <Route path='/home/informealumnobc/:idMatricula/:idGrado/:idGrupo/:idDocente/:Printer' component={props => <InformeAlumnobc {...props} />} exact />  
                <Route path='/home/actividadesdocente/:idDocente' component={props => <ActividadesDocente {...props} />} exact />  
                <Route path='/home/asignaturasdocente/' component={props => <AsignaturasDocente {...props} />} exact />  
                <Route path='/home/modificarindicadores/:idGrado/:idGrupo/:idAsignatura' component={ModificarIndicadores} exact />  
                <Route path='/home/consolidado/:idGrado/:idGrupo' component={Consolidado} exact />
                <Route path='/home/calificacionesfinalp/:idGrado/:idGrupo/:idAsignatura/:idPeriodo' component={Calificacionesfinalp} exact />
                <Route path='/home/consolidadoresumen/:idGrado/:idGrupo' component={ConsolidadoResumen} exact />
                
          </Suspense>          

    </div>
   

    );
  }

  componentDidMount() {
    this.listar_grados();
    this.obtener_periodo_actual()
  }  

}

export default Home;
