import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
class BTNConsolidado extends Component {
    constructor(props){

        

        super(props);
        this.state = {
            es_director: props.dgr,
            id_docente: props.id_docente,
            id_grado: props.id_grados,
            id_grupo: props.id_grupo            
        }
    }

    render() {

        let botondirector = null;

        if (this.state.es_director === "1"){
            botondirector = <><Button type="button" variant="outlined" 
                                            className="MargenBoton" 
                                            size="small"
                                            component={Link} to={`/home/consolidado/${this.state.id_grado}/${this.state.id_grupo}/`} 
                                            color="secondary">Consolidado</Button>
                            </>            
        }

        return (
          <>
               {botondirector}
          </>
        );
      }
}


export default BTNConsolidado;      