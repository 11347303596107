import React, { Component } from 'react';
import {PostData} from '../../services/PostData';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';

class ModalIndicador extends Component {

  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);  
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.guardar_indicador = this.guardar_indicador.bind(this);
    

    this.state = {
      index: props.index,  
      accion: props.accion,
      id_asigantura: props.id_asignatura,
      id_grado: props.id_grado,
      id_indicador: props.id_indicador,
      codigo_indic: props.codigo_indic,
      descripcion_indic: props.descripcion_indic,
      id_periodo: props.id_periodo,
      num_periodo: props.num_periodo,
      open: false,
      show: false,
      disabled: false,
      loading: false
    };
    
  }

  guardar_indicador(){
    if (this.state.id_indicador && this.state.descripcion_indic){
      this.setState({disabled: true, loading: true})  
      
      PostData('indicadores/save', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){           
            this.setState({disabled: false, loading: false}); 
            this.props.listar_indicadores_sub();
            
            this.handleClose();
        }else{
          this.setState({disabled: false}) 
          console.log("Error No se pudo guardar");
      }

    })
  }


  } 

  onChange(e){        
    this.setState({
        [e.target.name]: e.target.value
      });
  }   

  handleClose() {
    this.setState({ show: false, open: false });
  }

  handleShow(m) {
    console.log("clic abrir " + this.state.id_indicador);  
    this.setState({ show: true, open: true });
  }

  
  render() {
    let numeral = "#";
    let visibleboton = false;
    if (this.state.id_periodo >= this.state.num_periodo || this.state.id_periodo === "0")
        visibleboton = true;
    
    return (
      <>
        
      
        {/* <Button type="button" onClick={() => this.handleShow(1)} style={{display: visibleboton ? '' : 'none' }} >
        <Tooltip title="Editar Indicador" placement="left-start">
            <i className="material-icons iconoColorRosa">
            edit
            </i>
        </Tooltip>
        </Button>    */}

        <Link to={numeral} onClick={() => this.handleShow(1)} style={{display: visibleboton ? '' : 'none' }}>
                <Tooltip title="Editar Indicador" placement="left-start">
                      <i className="material-icons iconoColorRosa" >edit</i>
                </Tooltip></Link>
        
      

        <Dialog fullWidth={true} maxWidth={'sm'} aria-labelledby="customized-dialog-title" open={this.state.open}
         >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
           <Avatar className="IconoPaper">
             <i className="material-icons ColorAzul">
                 done_all
             </i>
            </Avatar>
                Modificar Indicadores
        </DialogTitle>
        <DialogContent >
          
            <DialogContentText>
                    La descripción del indicador solo puede contener un máximo de 250 caracteres y solo puede tener un máximo de 4 indicadores por periodo.
               <br /> <br />
            </DialogContentText>
            
            <TextField 
                name="codigo_indic" 
                label="Codigo" 
                defaultValue={this.state.codigo_indic} 
                InputProps={{
                    disabled: true
                }}
            />

            <TextField                
                name="descripcion_indic"
                autoFocus
                margin="dense"
                id="descripcion_indic"
                label="Descripción"
                type="text"
                multiline
                rowsMax={3}
                defaultValue={this.state.descripcion_indic}
                onChange={this.onChange} 
                fullWidth
            />
        <InputLabel className="label" 
                htmlFor="periodo">Periodo: </InputLabel>    
        <Select
          name="id_periodo"
          value={this.state.id_periodo}
          onChange={this.onChange}  
          className="LeftMargin"       
        >
          <MenuItem value={0}>-</MenuItem>
          <MenuItem value={1}>Periodo 1</MenuItem>
          <MenuItem value={2}>Periodo 2</MenuItem>
          <MenuItem value={3}>Periodo 3</MenuItem>
          <MenuItem value={4}>Periodo 4</MenuItem>
        </Select>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => this.handleClose()} color="primary">
              Cerrar
          </Button>
          <Button autoFocus variant="contained" disabled={this.state.loading} onClick={() => this.guardar_indicador()} color="secondary">
              <CircularProgress  style={{display: this.state.loading ? '' : 'none' }} /> Guardar Cambios
          </Button>          
        </DialogActions>
      </Dialog>

      </>
    );
  }

  componentDidMount() {
 
    
  }  
}

export default ModalIndicador;
