import React, { Component } from 'react';
import {GetData} from '../../../services/GetData';
import {Link} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';

class GradoMaterias extends Component {

  constructor(props){
    
    super(props);
        this.state = {
            id_docente: props.id_docente,
            id_grado: props.id_grados,
            id_grupo: props.id_grupo,
            lista_asignaturas: "",
            loading: false
        }
        this.listar_materias = this.listar_materias.bind(this);
  }

  listar_materias(){
  
    if (!this.state.id_docente || !this.state.id_grado || !this.state.id_grupo)
       return; 

      this.setState({loading: true});

    GetData('asignaciones/asignaturasdocente/' + this.state.id_docente + "/" + this.state.id_grado + "/" + this.state.id_grupo, this.state).then ((result) =>{
          let responseJSON = result;

          if (responseJSON.error === false){
              let lasigna = responseJSON.asignaturas.map(                         
                    item => <Link to={`/home/indicadores/${this.state.id_grado}/${this.state.id_grupo}/${item.id_asignatura}`} key={item.id_asignatura} params={{ id_grado: this.state.id_grado, id_asignatura: item.id_asignatura }} style={{ textDecoration: 'none' }} href="/home/indicadores">
                      <Fade in={this.state.loading} timeout={1000}>
                     <ListItem button>                                  
                                <ListItemText primary={item.nombre_asignatura} >
                                    
                                </ListItemText>
                            </ListItem></Fade></Link>);
              this.setState({lista_asignaturas: lasigna, loading: false});
              
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
  }    

  render() {
    
    let mensaje_loading = "";

    if(this.state.loading)
        mensaje_loading = "Espere....";

    return (
      <div>
          {mensaje_loading}
          <ul className="list-group list-group-flush">
              
                  {this.state.lista_asignaturas}
            
          </ul>
      </div>
    );
  }

  componentDidMount() {
    this.setState({loading: true});  
    this.listar_materias();
    
  }  
  
}

export default GradoMaterias;
