import { Button, IconButton, InputLabel, LinearProgress, MenuItem, Select, Switch, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import LoadingScreen from 'react-loading-screen'
import { GetData } from '../../services/GetData'
import { Usuario } from '../../services/Usuario'

import { Alarm, Check, Edit } from '@material-ui/icons'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import { PostData } from '../../services/PostData'
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AlumnosNotasPerdidas = () => {

    const usuario_session =  Usuario();
    const IdDocente = usuario_session.id_docente;
  
    const [Loading, setLoading] = useState(false)
    const [LoadingTabla, setLoadingTabla] = useState(false)
    const [EstadoGuardar, setEstadoGuardar] = useState(false)
    const [GradoGrupo, setGradoGrupo] = useState("")
    const [Grado, setGrado] = useState("")
    const [Grupo, setGrupo] = useState("")
    const [Periodo, setPeriodo] = useState(1)
    const [ListadoAlumnos, setListadoAlumnos] = useState([])

    useEffect(() => {
        let id_docente = usuario_session.id_docente;

        GetData('asignaciones/gradosdirectorgrupo/' + id_docente).then ((result) =>{
          if (result.error === false){       
              setGradoGrupo(result.grado.gradogrupo)        
              setGrado(result.grado.id_grados)
              setGrupo(result.grado.id_grupo)
              
          }
        });

      
    }, [])

    useEffect(() => {
        if (!Grado || !Grupo)
             return;

             setLoadingTabla(true)
             GetData('calificaciones/alumnosnotasperdidasdoc/' + Grado + '/' + Grupo + '/' + Periodo).then ((result) =>{
               setListadoAlumnos(result.datos) 
               setLoadingTabla(false)
            
            });
    }, [Grado, Grupo, EstadoGuardar, Periodo])
    
    
   const CambiarRevision = (id_seguim) => {
            alertify.confirm('Confirmar', '¿Confirmas la revisión?', 
            () => {
               
               const datos = {
                     id_seguimientoap: id_seguim
               } 
                // Acción a realizar si se hace clic en el botón "Aceptar"
               PostData('seguimientos/updaterevision', datos).then((result) => {
                    if (result.error === false){
                        setEstadoGuardar(!EstadoGuardar)
                        alertify.notify(result.mensaje, 'success')
                    }
               })
            },
            () => {
                // Acción a realizar si se hace clic en el botón "Cancelar" o se cierra el cuadro de diálogo
                console.log('Acción cancelada');
            });

   }

   const CambiarReporte = (id_seguim) => {
            alertify.confirm('Confirmar', '¿Confirmas que notificaste al padre de familia?', 
            () => {
               
               const datos = {
                     id_seguimientoap: id_seguim
               } 
                // Acción a realizar si se hace clic en el botón "Aceptar"
               PostData('seguimientos/updatereportado', datos).then((result) => {
                    if (result.error === false){
                        setEstadoGuardar(!EstadoGuardar)
                        alertify.notify(result.mensaje, 'success')
                    }
               })
            },
            () => {
                // Acción a realizar si se hace clic en el botón "Cancelar" o se cierra el cuadro de diálogo
                console.log('Acción cancelada');
            });

   }

  return (
    <>
    
    <div className="container" >

        <LoadingScreen
                      loading={Loading}
                      bgColor='#f1f1f1'
                      spinnerColor='#9ee5f8'
                      textColor='#676767'
                      logoSrc='/images/LogoCnotasGr.png'
                      text='...'
                    > 

          <div className="card" >
            <div className="card-header">
                  <Button type="button" variant="outlined" component={Link} to={`/home/`} color="primary"> Regresar </Button>                
                  <span className="h6 font_plataform fontRoboto LeftMargin">
                        <strong>Revisión de Bajas Calificaciones: Grado:</strong>  {GradoGrupo}

                        <InputLabel htmlFor="filtro-periodo" className="spacio_rigth15 ml-3">Período </InputLabel>
                      <Select
                        name="periodo"
                        value={Periodo}
                        onChange={(e) => setPeriodo(e.target.value)}
                        inputProps={{
                          name: 'periodo',
                          id: 'filtro-periodo',
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>

                      </Select> 
                  </span>
            </div>
         </div>
         <LinearProgress style={{visibility: LoadingTabla ? 'visible' : 'hidden' }} />
         <table className="table table-bordered table-hover table-sm tablcal tblcalificaciones">
                  <thead>
                    <tr className='table-primary' key={4545}>
                      <th width='5px'>N°</th>
                      <th width='300px'>Alumno</th>
                      <th>Asignatura</th>
                      <th>Indicador</th>
                      <th>Actividad</th>
                      <th>Nota</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListadoAlumnos.map((seg, key) => {
                      return(
                        <tr key={key}>
                        <td>{key+1}</td>
                        <td>{seg.nombre}</td>
                        <td>{seg.nombre_asignatura}</td>                                        
                        <td>{seg.descripcion_indic}</td>
                        <td>{seg.nombre_actividad}</td>
                        <td>{seg.nota}</td>
                        
                        </tr>
                      )
                    })}
                      
                  </tbody>
              </table>
              { ListadoAlumnos.length === 0 &&
                 <Alert key={1} variant="warning" style={{padding: '2px'}}>
                  Sólo los Coordinadores de grado puedes hacer las revisión de los seguimientos al proceso de Aprendizaje 
                </Alert>
              }

      </LoadingScreen>
      </div>          
    </>
  )
}

export default AlumnosNotasPerdidas